<template>
  <div class="c-waterfall">
       <waterfall :col="2" :width="(170 / 375) * winWidth" :gutterWidth="(11 / 375) * winWidth" :data="list"  :isTransition="false">
            <template>
                <c-poster-products></c-poster-products>
                <div class="img" v-for="(item, index) in list" :key="index" :style="{width: (170 / 37.5) + 'rem',height: (((170 * (item.zoom_height) / 230) +85) / 37.5) + 'rem'}" >
                    <div :style="{width: (170 / 37.5) + 'rem',height: (((170 * item.zoom_height / 230)) / 37.5) + 'rem'}" @click="onPageToDetail(item.prefix_id+item.id)">
                        <div class="c-waterfall-cover"></div>
                        <van-image :src="item.preview_image" :lazy-load="index > 6 ? true :false" width="100%" fit="cover" loading-icon="" error-icon=""></van-image>
                    </div>
                    <div class="info">
                        <c-image iUrl="line_s" iClass="line" iWidth="170" iHeight="4" v-if="item.imgbg_display_val == 'block'"></c-image>
                        <h6 @click="onPageToDetail(item.prefix_id+item.id)">{{item.workname}}</h6>
                        <div class="content" @click="onPageToMe(item.user_info.id)">
                            <div class="avatar">
                                <van-image :src="item.user_info.head_img" :width="36 / 37.5 + 'rem'" :height="36 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
                            </div>
                            <div class="user">
                                <p><i class="level">Lv.{{item.user_level}}</i><i class="level_name">{{item.user_level_name}}</i></p>
                                <p class="name">{{item.user_info.nickname || ''}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </waterfall>
  </div>
</template>

<script>
import { Image } from 'vant';
import CImage from "./CImage.vue";
import CPosterProducts from "./CPosterProducts.vue";
import { mapState } from "vuex"
export default {
    name: 'c-waterfall',
    components: {
        [Image.name]: Image,
        [CImage.name]: CImage,
        [CPosterProducts.name]: CPosterProducts
    },
    props: {
        list: {
            type: Array,
            default: (() => []),
            required: true
        },
        winWidth: {
            type: [String, Number],
            default: 0,
            required: true
        }
    },
    computed: {
        ...mapState({
            routeName: (state) => state.routeName,
        })
    },
    methods: {
        onPageToDetail(id) {
            if (this.routeName == 'homepage') {
                this.$emit('homepageStepPage', { to: 'detail', id: id })
            } else {
                this.$router.push({
                    name: 'detail',
                    params: { id: id }
                })
            }

        },
        onPageToMe(id) {
            if (this.routeName == 'homepage') {
                if (this.$route.params.id == id) {
                    this.$emit('reload')
                } else {
                    this.$emit('homepageStepPage', { to: 'homepage', id: id })
                }
            } else {
                this.$router.push({
                    name: "homepage",
                    params: { id: id, enterType: 'click' },
                    query: { tab: 0 }
                });
            }

        }
    }

}
</script>

<style>
.c-waterfall-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.c-waterfall .vue-waterfall{overflow-y: hidden; height: auto;}
.c-waterfall .img{border-radius: 4px; overflow-y: hidden; margin-bottom: 12px; background: #ededed;}
.c-waterfall .info{padding: 12px; background: #fff; height: 85px;}
.c-waterfall .info h6{font-size: 12px;color: #2E2E30; width:146px; overflow: hidden; text-overflow:ellipsis;white-space:nowrap;}
.c-waterfall .content{margin-top: 10px; display: flex; justify-content:flex-start; align-items: center;}
.c-waterfall .avatar{width:36px; height: 36px; border-radius:50%; overflow-y: hidden; margin-right: 12px;}
.c-waterfall .user{flex:1;}
.c-waterfall .user p{ font-size: 12px; overflow: hidden; text-overflow:ellipsis;white-space:nowrap; width:98px;}
.c-waterfall .user i{font-style: normal;}
.c-waterfall .level{color: #F85659; margin-right: 8px;}
.c-waterfall .level_name{color: #B3B3B3;}
.c-waterfall .name{margin-top: 2px; color: #666666;}
.c-waterfall .line{position: absolute; left: 0; top: -3px; z-index: 1; transform: translateZ(0);}
</style>